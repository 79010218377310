import _objectSpread from "/var/jenkins_home/workspace/admin_cf/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AppMain from '@/layout/components/AppMain';
export default {
  name: 'LoginLayout',
  components: {
    AppMain: AppMain
  },
  computed: _objectSpread({}, mapGetters(['siteData']))
};